.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* default css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  /* display: none; */
}

body {
  font-weight: 400;
  /* overflow-y: hidden; */
  position: relative;
  background-color: #ffffff !important;
  color: #000000 !important;
  /* font-family: "Centra", sans-serif !important; */
  /* -ms-overflow-style: none; /* IE and Edge */
  /* scrollbar-width: none; Firefox */
  font-style: normal;
  font-weight: 400;
  font-family: "Outfit", thin !important;
  letter-spacing: 1.25px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.v_line {
  border-left: 3px solid black;
  height: 30px;
  margin: 0 10px;
}

/* ... */

.top_container_info {
  background-color: #05061f;
  padding: 8px;
}

.top_container_info a {
  color: white;
}

.top_container_info a:hover {
  color: rgb(152, 152, 152);
}

.top_container_info .col-md-6 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.top_container_info .social_div {
  justify-content: end;
}

/************ Navbar Css ************/
nav.navbar {
  background-color: #f7f7f7;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #f7f7f7;
  position: fixed;
}
nav.navbar a.navbar-brand {
  width: 5%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(135, 135, 135, 0.81);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(147, 147, 147, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}

.social-icon a:hover svg {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  border: 1px solid #424242;
  /* padding: 18px 34px; */
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  border-radius: 20px;
}

.navbar-text button a {
  padding: 18px 34px;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #000000;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 20px;
}
.navbar-text button a:hover {
  color: #ffffff;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #121212;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #3b3b3b;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #050505;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #767676;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.custom-dropdown:hover .dropdown-menu {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  padding: 0;
  margin: 0;
  z-index: 8;
  border-radius: 3px;
  width: fit-content;
  /* background-color: white; */
}

/* end */

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 100px 0 100px 0;
  background: linear-gradient(0deg, rgba(27, 6, 18, 0.4), rgba(24, 7, 17, 0.4)),
    url("./assets/images/banner-bg.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  text-align: left;
}

.banner h1 {
  font-size: 75px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  border: 1px solid;
  padding: 16px;
  border-radius: 20px;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}

.clients_carousel {
  color: rgb(0, 0, 0);
}

.client_carousel_item {
  min-height: 15vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #545454;
  font-size: 17px;
}

.client_carousel_item img {
  width: 100px;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/* end */

/************ Contact Css ************/
.contact {
  /* background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%); */
  padding: 60px 0 100px 0;
  background-color: transparent;
}
.contact img {
  width: 92%;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact form input,
.contact form textarea {
  width: 100%;
  background: rgb(126 126 126 / 10%);
  border: 1px solid rgb(142 142 142 / 50%);
  border-radius: 10px;
  color: #343434;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #949494;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/* services */

.services .container {
  background: #ddddddf7;
  border-radius: 30px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
  margin-bottom: -50px;
  position: relative;
  z-index: 9;
}

.title_modern {
  margin-bottom: 10px;
}

.title_modern h2 {
  padding: 5px 0;
  margin: -1px;
  display: inline-block;
  border-bottom: 1px solid #6a4d8f;
  white-space: nowrap;
  padding-bottom: 10px;
  color: #5f5f5f;
}

.services h4 {
  font-weight: 700;
  color: cadetblue;
  font-size: 1.6rem;
  margin: 20px 0;
}

.services h5 {
  margin: 25px 0 10px 0;
}

.services p {
  color: #4d4d4d;
}

.why_work_with_us {
  text-align: start !important;
}

.why_work_with_us > * {
  display: flex;
}

.why_work_with_us img {
  width: 80px;
  margin: 10px;
}

/* what we do */

.what_we_do {
  text-align: left;
  padding: 30px 0;
}

.what_we_do h3 {
  font-weight: 500;
}

.what_we_do h5 {
  color: #0b095b;
}

.what_we_do .what_we_do_img {
  border-radius: 15px;
  margin-bottom: 15px;
}

.what_we_do .what_we_do_img img {
  border-radius: 15px;
}

.what_we_do .col-md-3 p {
  color: #6d6d6d;
}

.what_we_do_img {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  height: 150px;
}
.what_we_do_img::before {
  content: "";
  background: linear-gradient(90.21deg, #434343c9 -5.91%, #535353ca 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.what_we_do_img:hover::before {
  height: 100%;
}
.what_we_do_img_content {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.what_we_do_img:hover .what_we_do_img_content {
  top: 50%;
  opacity: 1;
}

/* image carousel */

.image_carousel {
  margin: 50px 0;
}

.image_carousel .carousel {
}

.image_carousel .carousel .carousel-inner {
  height: 55vh;
}

.image_carousel .carousel .carousel-inner .carousel-item {
  height: 55vh;
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* why choose us */
.why_choose_us {
  text-align: left;
  color: #666;
}

.why_choose_us h2 {
  font-size: 2.5rem;
}

.why_choose_us h3 {
  color: #61dafb;
}

.why_choose_us ul {
  /* list-style: none; */
  padding: 0 !important;
  width: 70%;
}

.why_choose_us ul li {
  margin: 4px 0;
}

/* solutions */

.solutions {
  padding: 20px 0;
}

.solutions h3 {
  color: #03697e;
  font-size: 35px;
}

.solutions h5 {
  margin: 5px 0;
  color: #040481;
  font-weight: 600;
}

.solutions p {
  color: #5f5e5e;
}

/* contact us */

.contact_us {
  background: linear-gradient(
      0deg,
      rgba(19, 16, 18, 0.63),
      rgba(26, 22, 24, 0.854)
    ),
    url("./assets/images/conference.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  text-align: left;
  min-height: 50vh;
}

.contact_us .col-md-6 {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
}

.contact_us button {
  background-color: transparent;
  border: 1px solid white;
  width: fit-content;
  padding: 10px 25px;
  font-weight: bold;
}

/* footer */

.footer {
  background-color: #1c1c1c;
  color: white;
  padding: 20px 0 0 0;
}

.footer h5 {
  font-weight: 700;
}

.footer .top_part {
  padding: 10px 0;
  border-bottom: 1px solid;
  text-align: left;
}

.footer .top_part ul {
  list-style: none;
  padding: 0 !important;
}

.footer .top_part ul li {
  margin: 6px 0;
}

.footer .bottom_part {
  padding-top: 15px;
}
.footer .bottom_part ul {
  display: flex;
}

.footer a {
  color: white;
}

.footer a:hover {
  color: rgb(169, 169, 169);
}

@media (max-width: 992px) {
  nav.navbar {
    background-color: #f7f7f7;
  }

  .navbar-collapse .navbar-nav {
    text-align: justify;
  }

  .banner h1 {
    font-size: 55px;
  }

  .i_frame_body .ratio-16x9 {
    --bs-aspect-ratio: 62.25%;
  }
}

@media (max-width: 768px) {
  .services .container {
    border-radius: 20px;
  }
  .i_frame_body .ratio-16x9 {
    --bs-aspect-ratio: 81.25%;
  }
}

@media (max-width: 481px) {
  .container .row {
    margin-left: 0;
    margin-right: 0;
  }

  .top_container_info {
    font-size: 10px;
  }

  .top_container_info .social_div {
    justify-content: space-evenly;
    padding: 5px 0 0 0;
  }

  .social-icon a {
    width: 25px;
    height: 25px;
  }

  nav.navbar {
    padding: 10px 0px;
  }

  nav.navbar a.navbar-brand {
    width: 12%;
    margin: 0;
  }

  .banner h1 {
    font-size: 35px;
  }

  .services .container {
    padding: 40px 5px;
  }

  .image_carousel {
    margin: 10px 0;
  }

  .image_carousel .carousel .carousel-inner {
    height: 30vh;
  }

  .image_carousel .carousel .carousel-inner .carousel-item {
    height: 30vh;
    background-position: bottom right;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .why_choose_us ul {
    width: 100%;
  }

  .solutions h3 {
    font-size: 30px;
  }

  .footer .top_part .badges img {
    width: 23%;
    margin: 0px;
  }
  .footer .row > * {
    padding: 0;
  }

  .footer .bottom_part .row .col-md-3 {
    width: fit-content;
    list-style: none;
    border-left: 1px solid;
    padding: 0 5px;
  }

  .footer .bottom_part ul {
    padding-left: 5px;
  }
}
